import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

export function setupErrorLogging() {
    if(process.env.NODE_ENV === "production") { 
        Sentry.init({
            dsn: 'https://5e999e31a34444a29b973cba61738e81@o1299662.ingest.sentry.io/6599843',
            integrations: [new BrowserTracing()],
            tracesSampleRate: 1.0,
        });
    }
}