import * as ko from 'knockout';

import { checkLogin, UserRole } from './api/check';
import { session } from '@core/session';

export const userRole = ko.observable<UserRole | null>(null);

export function initSession() {
    if (SANDBOX) {
        session.init(userRole, checkLogin, {
            apiKey: "AIzaSyAfDrMqS-KDBf4Hd1jxoUYBZnoiPqA6BfY",
            authDomain: "dev-sandbox-309906.firebaseapp.com",
            databaseURL: 'https://dev-sandbox-309906.firebaseio.com',
            projectId: "dev-sandbox-309906",
            storageBucket: "dev-sandbox-309906.appspot.com",
            messagingSenderId: "531551277855",
            appId: "1:531551277855:web:96fe5cb1ddc9b773fad62a"
        });
    } else {
        session.init(userRole, checkLogin, {
            apiKey: 'AIzaSyDpQjPYNyUQYCho8TgB4YyXnygpE70nnLg',
            authDomain: 'sfsa-agserv.firebaseapp.com',
            databaseURL: 'https://sfsa-agserv.firebaseio.com',
            projectId: 'sfsa-agserv',
            storageBucket: 'sfsa-agserv.appspot.com',
            messagingSenderId: '145537660030',
            appId: '1:145537660030:web:a42d9ced989e01e4ddd42f'
        });
    }
}
